<template>
  <div id="invite-award" @click="hcClick">
    <div
      class="good-btn"
      :style="{
        opacity:
          hasAll ||
          diffTime(showTime) > 0 ||
          diffTime(end_time) < 0 ||
          (is_merge == 0 && merge_type == 1)
            ? '1'
            : '0.6'
      }"
      :class="
        diffTime(showTime) > 0 || diffTime(end_time) < 0 || (is_merge == 0 && merge_type == 2)
          ? 'good-btn-gray'
          : 'good-btn-blue'
      "
    >
      <div v-if="diffTime(showTime) > 0">
        <div v-if="diffTime(showTime) > 24 * 60 * 60 * 1000">
          {{ showTimeText(showTime) }}
        </div>
        <div v-else>
          倒计时<van-count-down
            class="countDown"
            millisecond
            format="HH:mm:ss"
            :time="diffTime(showTime)"
            @finish="finistTime"
          />
        </div>
      </div>
      <div v-else>
        <span v-if="diffTime(end_time) < 0 || (is_merge == 0 && merge_type == 2)">合成结束</span>
        <span v-else>立即合成</span>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watch, onMounted } from 'vue';
import moment from 'moment';
import { nftUtils } from '@/utils';
import { Toast } from 'vant';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default {
  props: {
    time: {
      type: String,
      default: ''
    },

    end_time: {
      type: String,
      default: '' //结束时间
    },
    priority_start_time: {
      type: String,
      default: '' //优先开始时间
    },
    hasAll: {
      type: Boolean,
      default: true // 材料是否充足
    },
    is_merge: {
      type: Number,
      default: 1 //合成库存是否已经用完,0--已用完
    },
    server_time: {
      type: Number,
      default: 0 //服务器时间
    },
    merge_type: {
      type: Number,
      default: null // 合成类型 1概率合成 2单个藏品合成
    }
  },
  emits: ['finistTime', 'compoundHreo'],

  setup(props, ctx) {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      showTime: props.time,
      hasAll: props.hasAll,
      isLogin: nftUtils.isLogin(),
      isVerify: false //
    });
    watch(
      () => props.time,
      time => {
        state.showTime = time;
      }
    );
    watch(
      () => props.hasAll,
      hasAll => {
        state.hasAll = hasAll;
      }
    );
    watch(
      () => props.is_advance,
      is_advance => {
        state.is_advance = is_advance;
      }
    );
    watch(
      () => props.presale_start_time,
      presale_start_time => {
        state.presale_start_time = presale_start_time;
      }
    );
    watch(
      () => props.presale_end_time,
      presale_end_time => {
        state.presale_end_time = presale_end_time;
      }
    );
    onMounted(async () => {
      await store.dispatch('refreshLoginInfo');
      if (state.isLogin) {
        state.isVerify = store.state.loginInfo ? !!store.state.loginInfo.id_verify : false;
      }
    });
    const finistTime = () => {
      // state.showTime = 0;
      ctx.emit('finistTime');
    };
    //点击
    const hcClick = () => {
      if (!state.isLogin) {
        Toast('请先登陆');
        setTimeout(() => {
          router.push({ path: '/login' });
        }, 500);
        return;
      }

      if (!state.isVerify) {
        Toast('需要实名认证后才能合成');
        setTimeout(() => {
          router.push({ path: `/authentication` });
        }, 1000);
        return;
      }
      if (diffTime(state.showTime) > 0) {
        Toast('活动未开始');
        return;
      }

      if (diffTime(props.end_time) < 0) {
        Toast('合成已结束咯~');
        return;
      }

      if (props.is_merge == 0 && props.merge_type == 2) {
        Toast('合成数量已达上限');
        return;
      }

      if (!state.hasAll) {
        Toast('材料不足');
        return;
      }

      ctx.emit('compoundHreo');
    };

    // 获取格式时间
    const getTime = (time, format = 'MM月DD日 HH:mm') => {
      return moment(time).format(format);
    };

    const showTimeText = time => {
      if (moment(moment(time)).diff(moment(), 'day') >= 3) {
        return getTime(time, 'MM月DD日') + '合成';
      } else {
        return '倒计时' + formatDuring(diffTime(time));
      }
      // console.log(getTime(time) + '开售', '...2', props.is_pre_sale);
    };
    const formatDuring = millisecond => {
      var days = parseInt(millisecond / (1000 * 60 * 60 * 24));
      var hours = parseInt((millisecond % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

      return days + ' 天 ' + hours + ' 小时 ';
    };

    // 计算与当前时间时间差
    const diffTime = time => {
      return moment(moment(time)).diff(moment(props.server_time), 'millisecond');
    };

    return {
      ...toRefs(state),
      getTime,
      showTimeText,
      diffTime,
      finistTime,
      hcClick,
      formatDuring
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';

.t-c {
  text-align: center;
}

.good-btn {
  position: relative;
  display: flex;
  margin: 0 auto;
  width: 223px;
  height: 41px;
  justify-content: center;
  align-items: center;

  font-weight: 600;
  font-size: 16px;
  .countDown {
    display: inline-block;
    color: #fff;
    margin-left: 10px;
  }
}
.good-btn-gray {
  background-image: url('../imgs/comm/hcbtn_gre.png');
  background-size: 100% 100%;
  color: $fc;
}
.good-btn-blue {
  background-image: url('../imgs/comm/hcbtn.png');
  background-size: 100% 100%;
  color: $primaryc;
}
</style>
